export const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  let requests = { ...state.requests };
  requests[requestName] = requestState === 'REQUEST';
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    ...requests
  };
};

export const dateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'DATE_CHANGE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
};
