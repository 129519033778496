import api from 'api';

export function initialActionSuccess (type, payload) {
  return { type, payload };
}

export function genericAction (type, payload) {
  return { type, payload };
}

export function resetState () {
  return function (dispatch) {
    dispatch(initialActionSuccess('RESET_STATE'));
  };
}

export function loggedInCheck () {
  return function (dispatch) {
    return api.get('/logged-in-check/');
  };
}

export function initialUserLocation () {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_LOCATION_REQUEST'));
    dispatch(initialActionSuccess('GET_USER_REQUEST'));
    return api.get('/user-location/')
      .then((res) => {
        dispatch(initialActionSuccess('GET_LOCATION_SUCCESS', res.data.location));
        dispatch(initialActionSuccess('GET_USER_SUCCESS', res.data.user));
        dispatch(initialActionSuccess('GET_FEATURE_FLAGS_SUCCESS', res.data.feature_flags));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionBatchesMarket () {
  return function (dispatch, getState) {
    const { persistantState: { market } } = getState();
    const { categoryFilter, deliveryDate, supplierFilter } = market;
    let params = '';
    if (deliveryDate) {
      params += `?delivery_date=${deliveryDate}`;
      if (supplierFilter) {
        params += `&suppliers=${supplierFilter}`;
      }
      if (categoryFilter && categoryFilter[0] !== 'all') {
        params += `&categories=${categoryFilter}`;
      }
      const location = new URLSearchParams(window.location.search).get('location');
      if (location) {
        params += `&location=${location}`;
      }
    }

    dispatch(initialActionSuccess('GET_PRODUCTS_REQUEST'));
    return api.get(`/market/get-products/${params}`)
      .then((res) => {
        dispatch(initialActionSuccess('GET_PRODUCTS_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionMarketCustomer (params) {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_CUSTOMER_MARKET_APP_REQUEST'));
    dispatch(initialActionSuccess('GET_USER_REQUEST'));
    return api.get(`/market/get-customer/${params}`)
      .then((res) => {
        const { association, customer, user } = res.data;
        if (association) customer.approved_by_farm = association.approved_by_farm;
        dispatch(initialActionSuccess('GET_CUSTOMER_MARKET_APP_SUCCESS', customer));
        dispatch(initialActionSuccess('GET_USER_SUCCESS', user));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionMarketLocation (params) {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_LOCATION_REQUEST'));
    return api.get(`/market/get-location/${params}`)
      .then((res) => {
        dispatch(initialActionSuccess('GET_LOCATION_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionCustomerInfo () {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_CUSTOMER_MARKET_APP_REQUEST'));
    return api.get('/customer/get-info/')
      .then((res) => {
        dispatch(initialActionSuccess('GET_CUSTOMER_MARKET_APP_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionCustomerFarms (params) {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_CUSTOMERS_FARMS_REQUEST'));
    return api.get(`/customer/get-customers-farms/`)
      .then((res) => {
        dispatch(initialActionSuccess('GET_CUSTOMERS_FARMS_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionCustomerOrders (params) {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_ORDERS_REQUEST'));
    return api.get(`/customer/get-customers-orders/`)
      .then((res) => {
        dispatch(initialActionSuccess('GET_ORDERS_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}

export function initialActionCustomerUserInfo () {
  return function (dispatch) {
    dispatch(initialActionSuccess('GET_USER_REQUEST'));
    return api.get(`/customer/get-customer-user-info/`)
      .then((res) => {
        dispatch(initialActionSuccess('GET_USER_SUCCESS', res.data));
      }).catch((error) => {
        throw (error);
      });
  };
}
