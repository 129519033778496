import _ from 'lodash';

const persistantStateReducer = (state = {}, action) => {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case 'UPDATE_CUSTOMER_TABLE':
      return {
        ...state,
        customer: {
          ...action.payload
        }
      };
    case 'UPDATE_PRODUCTS_TABLE':
      newState.products.current = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_PRODUCT_WASTE_TABLE':
      newState.harvest.productWaste = { ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PRODUCT_HARVEST_TABLE':
      newState.harvest.productHarvest = { ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_ORDERS_TABLE':
      return {
        ...state,
        orders: {
          ...action.payload
        }
      };
    case 'UPDATE_INVENTORY_TAB':
      newState.inventory.tab = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_INVENTORY_WASTE_TABLE_FILTER':
      newState.inventory.inventoryWasteTable = { ...newState.inventory.inventoryWasteTable, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_INVENTORY_TABLE_FILTER':
      newState.inventory.inventoryTable = { ...newState.inventory.inventoryTable, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_CUSTOMER_TABLE_FILTER':
      newState.customer = { ...newState.customer, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_ORDER_TABLE_FILTER':
      newState.orders = { ...newState.orders, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PRODUCTS_TABLE_FILTER':
      newState.products = { ...newState.products, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PRODUCT_TAB':
      newState.products.tab = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_PLANTINGS_TABLE_FILTER':
      newState.plantings = { ...newState.plantings, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_SEEDS_TABLE_FILTER':
      newState.seeds = { ...newState.seeds, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PRODUCT_RECIPE_TABLE_FILTER':
      newState.productRecipes = { ...newState.productRecipes, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_INVENTORY_TABLE_PAGE':
      newState.inventory.inventoryTable = { ...newState.inventory.inventoryTable, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_ORDER_TABLE_PAGE':
      newState.orders = { ...newState.orders, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PLANTINGS_TABLE_PAGE':
      newState.plantings = { ...newState.plantings, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_ALL_TABLE_PAGE':
      newState.inventory.inventoryTable = { ...newState.inventory.inventoryTable, ...action.payload };
      newState.inventory.inventoryWasteTable = { ...newState.inventory.inventoryWasteTable, ...action.payload };
      newState.orders = { ...newState.orders, ...action.payload };
      newState.plantings = { ...newState.plantings, ...action.payload };
      newState.seeds = { ...newState.seeds, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_SHOW_CART_STATE':
      newState.market.showCart = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_MARKET_SEARCH_STATE':
      newState.market.marketSearch = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_MARKET_SUPPLIER_FILTER_STATE':
      newState.market.supplierFilter = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_MARKET_DELIVERY_DATE_STATE':
      newState.market.deliveryDate = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_MARKET_CATEGORY_FILTER_STATE':
      newState.market.categoryFilter = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_PRODUCTION_SCHEDULE_TAB':
      newState.productionSchedule.tab = action.payload;
      return {
        ...newState
      };
    case 'UPDATE_CUSTOMER_ORDER_PROFILE_TABLE_FILTER':
      newState.productionSchedule.customerOrderProfileTable = { ...newState.productionSchedule.customerOrderProfileTable, ...action.payload };
      return {
        ...newState
      };
    case 'UPDATE_PRODUCTION_SCHEDULE_TABLE_FILTER':
      newState.productionSchedule.productionScheduleTable = { ...newState.productionSchedule.productionScheduleTable, ...action.payload };
      return {
        ...newState
      };
    default:
      return {
        ...state
      };
  }
};

export default persistantStateReducer;
