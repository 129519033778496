import React from 'react';
import ReduxDevTools from 'components/DevTools/ReduxDevTools';
import LoadingComponent from 'components/Loading';
import Loader from 'components/Loading/Loader';
import ReactGA from 'react-ga';
import loadable from 'react-loadable';

import { 
  initialActionCustomerInfo,
  initialActionCustomerFarms,
  initialActionCustomerOrders,
  initialActionCustomerUserInfo,
  resetState
} from 'actions/initialAction';

import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createLoadingSelector } from 'selectors/loadingSelector';


// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/customer_app.scss"
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let AsyncCustomerAppLayout = loadable({
  loader: () => import('components/Layout/CustomerAppLayout/'),
  loading: LoadingComponent
})

class CustomerApp extends React.Component {
  componentDidMount(){
    const { store } = this.props;
    ReactGA.initialize('UA-134128367-1');
    store.dispatch(resetState());
    store.dispatch(initialActionCustomerInfo());
    store.dispatch(initialActionCustomerFarms());
    store.dispatch(initialActionCustomerOrders());
    store.dispatch(initialActionCustomerUserInfo());
  }
  render() {
    const { isLoading, location, match } = this.props;
    const isRoot = location.pathname === '/customer' || location.pathname === '/customer/' ? true : false;
    const isProduction = process.env.NODE_ENV === 'production';

    if (isLoading) {
      return (<div className="loader-container"> <Loader /> </div>)
    }
    if (isRoot) {
      return (<Redirect to={'/customer/orders/'} />);
    }

    return (
      <div id="app">
        <Route path={`${match.url}`} component={AsyncCustomerAppLayout} />
        {!isProduction && <ReduxDevTools />}
      </div>
    )
  }
}

const loadingSelector = createLoadingSelector(['GET_USER']);
const mapStateToProps = state => {
  return ({
    isLoading: loadingSelector(state)
  })
};

export default connect(
  mapStateToProps,
  null
)(CustomerApp);

