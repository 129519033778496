import { combineReducers } from 'redux';
import crudReducer from './crudReducer';
import settingsReducer from './settingsReducer';
import { routerReducer } from 'react-router-redux';
import { dateReducer, loadingReducer } from './utilityReducers';
import { filterChangeReducer } from './filterReducers';
import reduceReducers from 'reduce-reducers';
import locationReducer from './locationReducer';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import shoppingCartReducer from './shoppingCartReducer';
import persistantStateReducer from './persistantStateReducer';

const pointlessReducer = (state = {}, action) => {
  return {
    ...state
  };
};

const combinedReducer = combineReducers({
  settings: settingsReducer,
  routing: routerReducer,
  products: pointlessReducer,
  featureFlags: pointlessReducer,
  orders: pointlessReducer,
  location: locationReducer,
  user: userReducer,
  requests: loadingReducer,
  dateRange: dateReducer,
  filter: filterChangeReducer,
  customers: customerReducer,
  shoppingCart: shoppingCartReducer,
  persistantState: persistantStateReducer
});

const rootReducer = reduceReducers(combinedReducer, crudReducer);

export default rootReducer;
