import React from 'react';
import ReduxDevTools from 'components/DevTools/ReduxDevTools';
import LoadingComponent from 'components/Loading';
import loadable from 'react-loadable';

import { initialUserLocation } from 'actions/initialAction';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';


// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let AsyncAuthLayout = loadable({
  loader: () => import('components/Layout/Auth/'),
  loading: LoadingComponent
})

class Auth extends React.Component {
  componentDidMount() {
    const { store } = this.props;
    store.dispatch(initialUserLocation());
  }
  render() {
    const { location, match, nativeLocation, user } = this.props;
    const { active, customer_id, farm_id, id } = user;
    const stripeSubscription = nativeLocation.stripe_subscription;
    const isPaymentInfo = location.pathname === '/auth/payment-info' || location.pathname === '/auth/payment-info/'  ? true : false;
    if (id) {
      if (active) {
        if (farm_id && stripeSubscription) {
          return (<Redirect to={'/v1/dashboard'} />);
        } 
        else if (farm_id && !isPaymentInfo) {
          return (<Redirect to={'/auth/payment-info/'} />);
        }
        else if (customer_id) {
          return (<Redirect to={'/customer/'} />);
        }
      }
    }
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <div id="app">
        <Route path={`${match.url}`} component={AsyncAuthLayout} />
        {!isProduction && <ReduxDevTools />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return ({
    nativeLocation: state.location,
    user: state.user
  })
};

export default connect(
  mapStateToProps,
  null
)(Auth);
