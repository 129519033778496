const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

export function openModal (obj) {
  return { type: OPEN_MODAL, obj };
}

export function closeModal (obj) {
  return { type: CLOSE_MODAL, obj };
}

export function addFrom (type, payload) {
  let ADD_FROM = `ADD_FROM_${type}`;
  return { type: ADD_FROM, payload };
}

export function updateFrom (type, payload) {
  let UPDATE_FROM = `UPDATE_FROM_${type}`;
  return { type: UPDATE_FROM, payload };
}

export function ajaxRequest (type) {
  return { type: `AJAX_${type}` };
}
