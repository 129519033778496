import axios from 'axios';
import _ from 'lodash';
import { notification } from 'antd';

// Customized, because of typo

const openErrorNotification = (error_message) => {
    const args = {
        message: 'Error',
        description: error_message,
        duration: 6,
    };
    notification.error(args);
};

const openSuccessNotification = (success_message) => {
    const args = {
        message: 'Success!!',
        description: success_message || ' ',
        duration: 6,
    };
    notification.success(args);
};


let api;
if (process.env.NODE_ENV !== 'production') {
    api = axios.create({
        baseURL: 'http://localhost:8080',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
} else {
    api = axios;
}

api.interceptors.response.use(function (response) {
    if (response.data && response.data.success_message) {
        const { success_message } = response.data;
        openSuccessNotification(success_message);
        delete response.data.success_message
    }
    return response;
}, function (error) {
    try {
        if (error.response.status === 403) {
            window.location.href = '/v1/exception/403'
        }
        if (error.response.status === 401) {
            window.location.href = '/error/401'
        }
        if (error.response.status === 500) {
            openErrorNotification('Internal Server Error: Contact NATIVE if this persists');
        }
        if (error.response.status === 413) {
            openErrorNotification('File size must be smaller than 10 mb');
        }
        if (error.response.status === 415) {
            openErrorNotification('Invalid File Type');
        }
        if (error.response.status === 302) {
            const redirectUrl = _.get(error.response, 'data.redirect_url');
            if (redirectUrl) {
                window.location = `${window.location.origin}${redirectUrl}`;
            }
        }
        if (error.response.data && error.response.data.error_message) {
            const { error_message } = error.response.data;
            openErrorNotification(error_message);
        } else if (error.response.data instanceof Blob) {
            try { 
                error.response.data.text().then(text => {
                    const {error_message} = JSON.parse(text);
                    openErrorNotification(error_message)
                })
            } catch (e) {
                console.error(e)
            }
        }
    } catch {
        openErrorNotification('Internal Server Error: Contact NATIVE if this persists');
    }

    return Promise.reject(error);
});


api.defaults.xsrfCookieName = 'XSRF-TOKEN';
api.defaults.xsrfHeaderName = 'X-CSRFToken';
export default api;