import React from 'react';
import ReduxDevTools from 'components/DevTools/ReduxDevTools';
import LoadingComponent from 'components/Loading';
import ReactGA from 'react-ga';
import loadable from 'react-loadable';

import { 
  initialActionCustomerInfo,
  initialActionCustomerUserInfo,
  resetState
} from 'actions/initialAction';
import { Route } from 'react-router-dom';


// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let AsyncCheckoutLayout = loadable({
  loader: () => import('components/Layout/Checkout/'),
  loading: LoadingComponent
})

class Checkout extends React.Component {
  componentDidMount(){
    const { store } = this.props;
    ReactGA.initialize('UA-134128367-1');
    store.dispatch(resetState());
    store.dispatch(initialActionCustomerInfo());
    store.dispatch(initialActionCustomerUserInfo());
  }
  render() {
    const { match } = this.props;
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <div id="app">
        <Route path={`${match.url}`} component={AsyncCheckoutLayout} />
        {!isProduction && <ReduxDevTools />}
      </div>
    )
  }
}

export default Checkout;

