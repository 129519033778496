import React from 'react';
import _ from 'lodash';
import api from 'api';

import { ajaxRequest } from 'actions/modalActions';
import { updateUserSettings } from 'actions/settingsActions';
import { Button, Modal } from 'antd'
import { connect } from 'react-redux';


class TermsOfService extends React.Component {

    constructor(props) {
        super(props)
        this.onAccept = this.onAccept.bind(this);
    }

    lockBody = () => {
        document.body.classList.add('terms-of-service-open');
        return (document.body)
    }

    onAccept = async () => {
        this.props.handleAjaxRequest('REQUEST');
        try {
            const response = await api.post('/accept-terms-of-service/');
            document.body.classList.remove('terms-of-service-open');
            this.props.updateUser(response.data)
        } catch (err) {
            console.error(err);
        }
        this.props.handleAjaxRequest('SUCCESS');
    }

    render() {
        const { user } = this.props;
        const termsOfServiceNotSigned = _.get(user, 'tos_accepted_at', '') === null;
        if (termsOfServiceNotSigned) {
            this.lockBody()
        }
        return (
            <Modal
                title="Terms of Service"
                visible={termsOfServiceNotSigned}
                wrapClassName="terms-of-service"
                closable={false}
                centered
                onOk={this.onAccept}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        loading={this.props.ajaxInProgress}
                        onClick={this.onAccept}>
                        I Accept
                    </Button>,
                ]}
            >
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://s3.amazonaws.com/nativeag/public/Native+AgTech%2C+Inc._Privacy+Policy+(LS+1.28.19)+2.pdf">Our Privacy Policy</a>
                </div>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://s3.amazonaws.com/nativeag/public/Native+AgTech%2C+Inc._Terms+of+Use+(LS+1.28.19)+2.pdf">Our Terms of Use</a>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return ({
        user: state.user,
        ajaxInProgress: state.requests.AJAX
    })
};

const mapDispatchToProps = dispatch => ({
    updateUser: payload => {
        dispatch(updateUserSettings(payload));
    },
    handleAjaxRequest: (type) => {
        dispatch(ajaxRequest(type));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsOfService);