import React from 'react';
import LoadingComponent from 'components/Loading';
import Loader from 'components/Loading/Loader';
import ReduxDevTools from 'components/DevTools/ReduxDevTools';
import ReactGA from 'react-ga';
import loadable from 'react-loadable';

import {
  initialUserLocation,
  loggedInCheck,
  resetState
} from 'actions/initialAction';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { createLoadingSelector } from 'selectors/loadingSelector';


// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let AsyncAppLayout = loadable({
  loader: () => import('components/Layout/AppLayout/'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let AsyncCallback = loadable({
  loader: () => import('routes/callback/'),
  loading: LoadingComponent
})

class App extends React.Component {
  componentDidMount(){
    const { store } = this.props;
    ReactGA.initialize('UA-134128367-1');
    store.dispatch(resetState());
    store.dispatch(initialUserLocation());
    store.dispatch(loggedInCheck());
  }
  render() {
    const { match, location, isLoading } = this.props;
    const isRoot = location.pathname === '/' ? true : false;
    const isProduction = process.env.NODE_ENV === 'production';
    if (isLoading) {
      return (<div className="loader-container"> <Loader /> </div>)
    }
    if (isRoot) {
      return (<Redirect to={'/v1/dashboard'} />);
    }
    return (
      <div id="app">
        <Route path={`${match.url}v1`} component={AsyncAppLayout} />
        <Route path={`${match.url}exception`} component={AsyncException} />
        <Route path={`${match.url}callback`} component={AsyncCallback} />
        {!isProduction && <ReduxDevTools />}
      </div>
    )


  }
}

const loadingSelector = createLoadingSelector(['GET_LOCATION', 'GET_USER']);
const mapStateToProps = state => {
  return ({
    isLoading: loadingSelector(state)
  })
};

export default connect(
  mapStateToProps,
  null
)(App);
