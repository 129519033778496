import _ from 'lodash';

const shoppingCart = (state = {}, action) => {
  let newState = _.cloneDeep(state);
  if (!action.payload) return { ...state };
  const { key, product } = action.payload;
  switch (action.type) {
    case 'ADD_TO_CART':
      newState[key] = product;
      return {
        ...newState
      };
    case 'REMOVE_FROM_CART':
      if (key) {
        delete newState[key];
      } else {
        newState = {};
      }
      return {
        ...newState
      };
    case 'UPDATE_CART_ITEM':
      newState[key].quantity += product.quantity;
      return {
        ...newState
      };
    default:
      return {
        ...state
      };
  }
};

export default shoppingCart;
