const location = (state = {}, action) => {
  switch (action.type) {
    case 'GET_CUSTOMERS_FARMS_SUCCESS':
    case 'GET_LOCATION_SUCCESS':
    case 'UPDATE_FROM_LOCATION':
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default location;
