export const filterChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'FILTER_CHANGE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'FILTER_CHANGE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
};
