import React from 'react';
import ReduxDevTools from 'components/DevTools/ReduxDevTools';
import LoadingComponent from 'components/Loading';
import Loader from 'components/Loading/Loader';
import ReactGA from 'react-ga';
import loadable from 'react-loadable';

import { initialActionMarketCustomer, initialActionMarketLocation, resetState } from 'actions/initialAction';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { createLoadingSelector } from 'selectors/loadingSelector';

// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let AsyncMarketAppLayout = loadable({
  loader: () => import('components/Layout/MarketAppLayout/'),
  loading: LoadingComponent
})

class MarketApp extends React.Component {
  componentDidMount(){
    const { history, store } = this.props;
    ReactGA.initialize('UA-134128367-1');
    const params = history.location.search;
    store.dispatch(resetState());
    store.dispatch(initialActionMarketLocation(params));
    store.dispatch(initialActionMarketCustomer(params));
  }
  render() {
    const { isLoading, location, match } = this.props;
    const isProduction = process.env.NODE_ENV === 'production';
    const isRoot = location.pathname === '/market' || location.pathname === '/market/'  ? true : false;

    if (isLoading) {
      return (<div className="loader-container"> <Loader /> </div>)
    }
    if (isRoot) {
      return (<Redirect to={'/market/products'} />);
    }
    return (
      <div id="app">
        <Route path={`${match.url}`} component={AsyncMarketAppLayout} />
        {!isProduction && <ReduxDevTools />}
      </div>
    )


  }
}

const loadingSelector = createLoadingSelector(['GET_LOCATION', 'GET_USER']);
const mapStateToProps = state => {
  return ({
    isLoading: loadingSelector(state)
  })
};

export default connect(
  mapStateToProps,
  null
)(MarketApp);

