// TODO figure out how to preload state, could possibly happen here
import moment from 'moment';

function initializeState () {
  return {
    products: { data: {}, pricing: {}, variants: {} },
    user: {},
    featureFlags: {},
    location: {},
    orders: { data: {} },
    dateRange: [moment().subtract(90, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    filter: { product: [], customer: [], productBatch: [], subFarm: []},
    customers: { data: {} },
    requests: { 'AJAX': false },
    persistantState: {
      customer: { current: 1, order: 'ascend', sortBy: 'name', tableFilterValue: '' },
      plantings: { current: 1, order: 'descend', sortBy: 'date_of_planting', tableFilterValue: '' },
      productionSchedule: {
        tab: '1',
        customerOrderProfileTable: { current: 1, order: 'ascend', sortBy: 'customer_name', tableFilterValue: '' },
        productionScheduleTable: { current: 1, order: 'descend', sortBy: 'delivery_date', tableFilterValue: '' }
      },
      products: { current: 1, order: 'ascend', sortBy: 'name', tab: '1', tableFilterValue: '' },
      productRecipes: { current: 1, order: 'ascend', sortBy: 'farm_product_name', tableFilterValue: '' },
      inventory: {
        inventoryWasteTable: { current: 1, order: 'descend', sortBy: 'created_at', tableFilterValue: '' },
        inventoryTable: { current: 1, order: 'descend', sortBy: 'date_of_harvest', tableFilterValue: '' },
        tab: '1'
      },
      orders: { current: 1, order: 'descend', sortBy: 'delivery_date', tableFilterValue: '' },
      market: { categoryFilter: ['all'], deliveryDate: null, marketSearch: '', showCart: false, supplierFilter: [] },
      seeds: { current: 1, order: 'descend', sortBy: 'date_of_purchase', tableFilterValue: '' },
    }
  };
}

export default initializeState;
