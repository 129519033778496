import React from 'react';
import LoadingComponent from 'components/Loading';
import ScrollToTop from 'components/ScrollToTop';
import TermsOfService from 'components/TermsOfService'
import loadable from 'react-loadable';

import App from './Apps/App';
import Auth from './Apps/Auth';
import Checkout from './Apps/Checkout';
import CustomerApp from './Apps/CustomerApp';
import MarketApp from './Apps/MarketApp';
import TransparencyApp from './Apps/TransparencyApp';

import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import configureStore, { history } from 'store/configureStore';

const store = configureStore();

let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})

export default class Root extends React.Component {
  constructor() {
    super();
    this.state = {stripe: null};
  };

  routeWithProps(Component, store, props) {
    return (
        <Component store={store} {...props}/>
      );
    }

  render() {
    return (
      <Provider store={store}>
        <div>
          <ConnectedRouter history={history}>
            <ScrollToTop>
              <Switch>
                <Route path='/market' render={(props) => this.routeWithProps(MarketApp, store, props)}/>
                <Route path='/customer' render={(props) => this.routeWithProps(CustomerApp, store, props)} />
                <Route path='/checkout' render={(props) => this.routeWithProps(Checkout, store, props)} />
                <Route path='/auth' render={(props) => this.routeWithProps(Auth, store, props)} />
                <Route path='/transparency' render={(props) => this.routeWithProps(TransparencyApp, store, props)} />
                <Route path='/t' render={(props) => this.routeWithProps(TransparencyApp, store, props)} />
                <Route path='/error' component={AsyncException} />
                <Route path='/' render={(props) => this.routeWithProps(App, store, props)} />
              </Switch>
            </ScrollToTop>
          </ConnectedRouter>
          <TermsOfService />
        </div>
      </Provider>
    );
  }
}
