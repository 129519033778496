import _ from 'lodash';

const customerReducer = (state = {}, action) => {
  let { data } = _.cloneDeep(state);
  switch (action.type) {
    case 'UPDATE_FROM_CUSTOMER_MARKET_APP':
    case 'GET_CUSTOMER_MARKET_APP_SUCCESS':
      return {
        ...state,
        data: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default customerReducer;
