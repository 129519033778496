import _ from 'lodash';
import initializeState from 'store/initialState';

const crudReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: {
          ...action.payload
        }
      };
    case 'GET_ORDERS_SUCCESS':
      return {
        ...state,
        orders: {
          data: action.payload,
        }
      };
    case 'GET_FEATURE_FLAGS_SUCCESS':
      return {
        ...state,
        featureFlags: action.payload
      };
    case 'RESET_STATE':
      const resetState = initializeState();
      return {
        ...state,
        ...resetState
      };
    default:
      return {
        ...state
      };
  }
};

export default crudReducer;
